import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getRemoteConfig } from "firebase/remote-config";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: window.env.REACT_APP_API_KEY,
  authDomain: window.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: window.env.REACT_APP_DATABASE_URL,
  projectId: window.env.REACT_APP_PROJECT_ID,
  storageBucket: window.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: window.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: window.env.REACT_APP_APP_ID,
  measurementId: window.env.REACT_APP_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const remoteConfig = getRemoteConfig(app);
remoteConfig.settings.minimumFetchIntervalMillis = 3600000;
export const auth = getAuth();
export const db = getFirestore(app);
export default firebaseConfig;
