import {
  Timestamp,
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { db } from "../../services/firebaseConfig";

export const getMessages = async (userID) => {
  const q = query(collection(db, "MESSAGES"), where("userID", "==", userID));
  const querySnapshot = await getDocs(q);
  const data = [];
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    // console.log(doc.data());
    data.push(doc.data());
  });
  // console.log("Messages Data", data);
  // Comparison function to sort objects by date
  const compareByDate = (a, b) => {
    const dateA = a.timestamp;
    const dateB = b.timestamp;

    // Compare the dates
    if (dateA < dateB) {
      return -1;
    }
    if (dateA > dateB) {
      return 1;
    }
    return 0;
  };

  // Sort the items array by date
  data.sort(compareByDate);

  // Output the sorted items
  // console.log(data);
  return data;
};
export const createMessage = async (data) => {
  // console.log("Creating Message");
  const now = new Date();
  const final = {
    ...data,
    updateDate: Timestamp.fromDate(now),
  };
  // console.log("Final Message Data:", final);
  await setDoc(doc(db, "MESSAGES", data?.id), {
    ...final,
  });
  return final;
};
export const editMessage = async (data) => {
  // console.log("Editing Chat");
  const now = new Date();
  const final = {
    ...data,
    updateDate: Timestamp.fromDate(now),
  };
  // console.log("Final Data:", final);
  await setDoc(doc(db, "MESSAGES", data?.id), {
    ...final,
  });
  return final;
};
