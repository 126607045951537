import React from "react";
export const FlexRow = ({
  style,
  onClick = () => {},
  className = " ",
  key = 0,
  alignItems = "center",
  height = "auto",
  justifyContent = "center",
  width = "100%",
  maxWidth = "100%",
  children,
}) => {
  return (
    <div
      style={{
        width: width,
        height: height,
        display: "flex",
        flexDirection: "row",
        alignItems: alignItems,
        justifyContent: justifyContent,
        maxWidth: maxWidth,
        ...style,
      }}
      onClick={onClick}
      key={key}
      className={`flexRow ${className}`}
    >
      {children}
    </div>
  );
};

export const FlexColumn = ({
  style,
  onClick = () => {},
  alignItems = "center",
  height = "auto",
  justifyContent = "center",
  width = "100%",
  maxWidth = "100%",
  className = " ",
  key = 0,
  children,
}) => {
  return (
    <div
      style={{
        width: width,
        height: height,
        display: "flex",
        flexDirection: "column",
        justifyContent: justifyContent,
        alignItems: alignItems,
        maxWidth: maxWidth,
        ...style,
      }}
      onClick={onClick}
      key={key}
      className={`flexColumn ${className}`}
    >
      {children}
    </div>
  );
};
