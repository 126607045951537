import React from "react";
import { HeaderSearch } from "./components/HeaderSearch";

const Header = ({ activeLink, opened, toggle }) => {
  const links = [
    {
      link: "/chat",
      label: "Chat",
    },
    {
      link: "/diary",
      label: "Diary",
    },
    {
      link: "/onitsha",
      label: "Onitsha",
    },
    {
      link: "/features",
      label: "Features",
    },
    {
      link: "/wallet",
      label: "Wallet",
    },

    {
      label: "Contact",
      link: "https://wa.me/237620302414",
    },
  ];
  return (
    <HeaderSearch
      links={links}
      opened={opened}
      toggle={toggle}
      activeLink={activeLink}
    />
  );
};

export default Header;
