export const formatAmountWithComma = (amount, separator = ",") => {
  return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
};
export const formatAmount = (amount) => {
  const digits = amount.toString().length;
  if (digits >= 4 && digits <= 6) {
    return `${(amount / 1000).toFixed(1)}K`;
  } else if (digits >= 7 && digits <= 9) {
    return `${(amount / 1000000).toFixed(1)}M`;
  } else if (digits >= 10 && digits <= 12) {
    return `${(amount / 1000000000).toFixed(1)}B`;
  } else if (digits >= 13 && digits <= 15) {
    return `${(amount / 1000000000000).toFixed(1)}T`;
  } else {
    return amount;
  }
};
