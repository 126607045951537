import { LoadingOverlay, ScrollArea } from "@mantine/core";
import React, { useEffect, useRef } from "react";
import Message from "./Message";
import Scrollbars from "react-custom-scrollbars-2";
import { useDispatch, useSelector } from "react-redux";
import { getMessages } from "../../store/messages/messageJobs";
import { saveMessage } from "../../store/messages/messagesReducer";
import { loadingNotify } from "./Notify";

const ChatBox = () => {
  // const activeChat = useSelector((state) => state.activeChat.chat);
  const messages = useSelector((state) => state.messages);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const viewport = useRef();

  const scrollToBottom = () =>
    viewport.current.scrollTo({
      top: viewport.current.scrollHeight,
      behavior: "smooth",
    });
  useEffect(() => {
    if (user?.displayName) {
      let loaded = false;
      loadingNotify({
        state: loaded,
        preCode: "Getting New Messages....",
        preMessage: "Please Wait....",
        postCode: "Got it",
        postMessage: "Loaded!",
      });
      getMessages(user.uid).then((res) => {
        if (res) {
          dispatch(saveMessage(res));
          // console.log(res);
        }
      });
      loaded = true;
      scrollToBottom();
    }
  }, [dispatch, messages?.length, user?.displayName, user.uid]);

  return (
    <Scrollbars>
      <ScrollArea
        style={{
          width: "100%",
          display: "flex",
          alignItems: "flex-end",
          height: "65vh",
          borderRadius: "10px",
          justifyContent: "flex-end",
        }}
        id="msg-box"
        viewportRef={viewport}
        // onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
      >
        {/* <LoadingOverlay
          visible={activeChat?.messages === undefined}
          overlayBlur={2}
          loaderProps={{ size: "sm", color: "violet", variant: "bars" }}
        /> */}
        <LoadingOverlay
          visible={messages === null}
          overlayBlur={2}
          loaderProps={{ size: "sm", color: "violet", variant: "bars" }}
          zIndex={1}
        />

        {messages?.length
          ? messages?.map((message, index) => {
              return message.isDeleted ? null : (
                <Message
                  key={index}
                  // styles={styles}
                  mainMessage={message}
                  index={index}
                />
              );
            })
          : null}
      </ScrollArea>
    </Scrollbars>
  );
};

export default ChatBox;
