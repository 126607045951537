import { createSlice } from "@reduxjs/toolkit";

export const activeChatSlice = createSlice({
  name: "activeChat",
  initialState: {
    chat: {},
  },
  reducers: {
    setActiveChat: (state, action) => {
      state.chat = { ...action.payload };
      // console.log("New Chat State", state);
      return state;
    },
  },
});

export const { setActiveChat } = activeChatSlice.actions;
export default activeChatSlice.reducer;
