import { ActionIcon, useMantineTheme, Textarea } from "@mantine/core";
import { IconArrowRight, IconArrowLeft } from "@tabler/icons-react";
import "./Components.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getRandomSendErrorMessage } from "../../workers/errorMessage";
import { toast } from "react-hot-toast";
import logo from "../../assets/Onitsha.gif";
import { sendMessage } from "../../services/messageService";
import { addMessage } from "../../store/messages/messagesReducer";
import { errorNotify } from "./Notify";

export function MessageInput({ text, setText }) {
  const theme = useMantineTheme();

  // const activeChat = useSelector((state) => state.activeChat.chat);
  const user = useSelector((state) => state.user);
  const messages = useSelector((state) => state.messages);

  // const messages = groupedMessages?.map((msg) => msg.messages);

  const [message, setMessage] = useState("");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [tokensUsed, setTokensUsed] = useState(0);

  const getPercent = (val, max) => {
    let ans = 100 - (val / max) * 100;
    // console.log("Chat Percent", parseFloat(ans.toFixed(0)));
    return parseFloat(ans.toFixed(0));
  };
  const CalcTokens = () => {
    let temp = 0;
    messages.map(
      (msg) =>
        // setTokensUsed(tokensUsed + msg.tokensUsed)
        // console.log(msg.tokensUsed);
        (temp = temp + msg.tokensUsed)
    );
    // console.log("Temp is:", messages.length);
    setTokensUsed(temp);

    return tokensUsed;
  };

  const send = () => {
    if (getPercent(tokensUsed, user?.tokens) > 1) {
      const userName = user.displayName;
      // Global flag required when calling replaceAll with regex
      const regex = / /gi;
      // console.log(userName.replaceAll(regex, "_"));

      const msgToSend = {
        name: userName.replaceAll(regex, "_") || "User",
        role: "user",
        content: message,
      };
      const prevSession = messages?.length
        ? [...messages[messages?.length - 1].messages]
        : [];

      const msgTS = prevSession?.length
        ? [...prevSession, msgToSend]
        : [msgToSend];

      // console.log("Prev Session", prevSession);
      // console.log("Messages to Send", msgTS);
      const data = {
        // chat: activeChat,
        state: false,
        // chatID: activeChat.id,
        userID: user.uid,
        messages: msgTS,
      };
      setLoading(!data.state);

      sendMessage({ ...data }).then((res) => {
        setMessage("");
        if (res.forState.id?.length) {
          dispatch(addMessage(res.forState));
        }

        setLoading(false);
      });
    } else {
      errorNotify({
        code: "Energy Low",
        message: "Insufficient Energy. Please Refill Chat Coins",
      });
    }
  };
  useEffect(() => {
    if (text?.length) {
      setMessage(text);
    }
    if (messages?.length) {
      CalcTokens();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages?.length, text]);
  return (
    <Textarea
      aria-label="Type a Message to ChatGDA"
      autosize
      maxRows={3}
      autoFocus
      disabled={loading}
      icon={
        <img
          src={logo}
          alt="ChatGDA"
          width={32}
          height={32}
          style={{ pointerEvents: "none" }}
        />
      }
      radius="xl"
      size="md"
      spellCheck={true}
      style={{
        width: "90%",
        height: "auto",
        scrollbarWidth: "none",
        marginTop: "1em",
        marginBottom: "1em",
        // borderColor: message?.length ? "indigo !important" : "gray !important",
      }}
      className="messageInput"
      value={message}
      onKeyUp={(e) => {
        if (e.key.match("Enter") && !e.ctrlKey) {
          if (message.length) {
            send();
            setMessage("");
            setText("");
          } else {
            const msg = getRandomSendErrorMessage();
            toast.error(msg);
          }
        } else if (e.key.match("Enter") && e.ctrlKey) {
          e.preventDefault();
          setMessage((prevText) => prevText + "\n");
        }
      }}
      onChange={(e) => setMessage(e.target.value)}
      rightSection={
        loading ? (
          <img
            src={logo}
            alt="ChatGDA"
            width={32}
            height={32}
            style={{ pointerEvents: "none" }}
          />
        ) : (
          <ActionIcon
            size={32}
            radius="xl"
            style={{
              backgroundColor:
                theme.colorScheme === "dark" ? theme.colors.dark[4] : "#f2e0ff",
              color: theme.colorScheme === "dark" ? "#f2e0ff" : "#000",
              cursor: "pointer",
            }}
            variant="filled"
            className="sendBtn"
            disabled={text?.length}
            onClick={() => {
              if (message.length) {
                send();
                setMessage("");
                setText("");
              } else {
                const msg = getRandomSendErrorMessage();
                // toast.error(msg);
                errorNotify({
                  code: "Error",
                  message: msg,
                });
              }
            }}
          >
            {theme.dir === "ltr" ? (
              <IconArrowRight size="1.1rem" stroke={1.5} />
            ) : (
              <IconArrowLeft size="1.1rem" stroke={1.5} />
            )}
          </ActionIcon>
        )
      }
      placeholder="Ask anything...."
      rightSectionWidth={42}
    />
  );
}

export default MessageInput;
