import { createSlice } from "@reduxjs/toolkit";
import { readUser } from "./userJobs";

export const userReducer = createSlice({
  name: "user",
  initialState: {
    auth: false,
    user: {},
  },
  reducers: {
    authenticate: (state, action) => {
      readUser(action.payload).then((res) => {
        state = {
          user: { ...res },
        };
        // console.log("User Received:", res);
        save(res);
      });
    },
    save: (state, action) => {
      state = {
        user: action.payload,
      };
      // console.log("User to Save:", action.payload);
      return action.payload;
    },
    logout: (state, action) => {
      state = {};
      window.location.reload();
    },
  },
});

export const { authenticate, save, logout } = userReducer.actions;
export default userReducer.reducer;
