import { useMantineColorScheme } from "@mantine/core";
import React from "react";

const Text = ({
  style = {},
  className = "",
  fontSize = 12,
  fontWeight = 400,
  color,
  key = 0,
  onClick = () => {},
  children,
}) => {
  const { colorScheme } = useMantineColorScheme();

  return (
    <div
      style={{
        fontSize: fontSize,
        fontWeight: fontWeight,
        color: color || colorScheme === "dark" ? "#FFF" : "#000",
        ...style,
      }}
      className={`text ${className}`}
      onClick={onClick}
      key={key}
    >
      {children}
    </div>
  );
};

export default Text;
