import React, { useState } from "react";
import Logo from "../../assets/Onitsha.gif";
import {
  createStyles,
  Navbar,
  UnstyledButton,
  Tooltip,
  Text,
} from "@mantine/core";
import {
  IconMessageChatbot,
  IconNotebook,
  IconBuildingStore,
  IconFingerprint,
  IconRobot,
  IconBrandWhatsapp,
} from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { FlexColumn } from "../../components/Flex";
import { getAuth } from "firebase/auth";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/user/userReducer";
import Scrollbars from "react-custom-scrollbars-2";
import storageAvailable from "../../utils/storageAvailable";

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: "flex",
    width: "auto",
  },

  aside: {
    flex: "0 0 60px",
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRight: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[3]
    }`,
  },

  main: {
    flex: 1,
    minWidth: "35vw",
    maxWidth: "35vw",
    overflow: "hidden",
    overflowY: "scroll",
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[6]
        : theme.colors.gray[0],
  },

  mainLink: {
    width: 44,
    height: 44,
    borderRadius: theme.radius.md,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[7],

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[5]
          : theme.colors.gray[0],
    },
  },

  mainLinkActive: {
    backgroundColor: "#f2e0ff",
    "&, &:hover": {
      backgroundColor: theme.fn.variant({
        variant: "light",
        color: theme.primaryColor,
      }).background,
      color: theme.fn.variant({ variant: "light", color: theme.primaryColor })
        .color,
    },
  },

  title: {
    boxSizing: "border-box",
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
    height: "12vh",
    borderBottom: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[3]
    }`,
    marginBottom: "1em",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  // activeIcon: {
  //   backgroundColor: "#f2e0ff",
  // },

  logo: {
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "12vh",
    borderBottom: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[3]
    }`,
    marginBottom: theme.spacing.xl,
  },

  link: {
    boxSizing: "border-box",
    display: "block",
    textDecoration: "none",
    borderTopRightRadius: theme.radius.md,
    borderBottomRightRadius: theme.radius.md,
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[7],
    padding: `0 ${theme.spacing.md}px`,
    fontSize: theme.fontSizes.sm,
    marginRight: theme.spacing.md,
    fontWeight: 500,
    height: 44,
    lineHeight: "44px",

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[5]
          : theme.colors.gray[1],
      color: theme.colorScheme === "dark" ? theme.white : theme.black,
    },
  },

  linkActive: {
    "&, &:hover": {
      borderLeftColor: theme.fn.variant({
        variant: "filled",
        color: theme.primaryColor,
      }).background,
      backgroundColor: theme.fn.variant({
        variant: "filled",
        color: theme.primaryColor,
      }).background,
      color: theme.white,
    },
  },
}));

const mainLinksMockdata = [
  { icon: IconMessageChatbot, label: "Chat", link: "chat" },
  { icon: IconNotebook, label: "Diary", link: "diary" },
  { icon: IconBuildingStore, label: "Onitsha", link: "onitsha" },
  { icon: IconRobot, label: "Features", link: "features" },
  { icon: IconFingerprint, label: "Wallet", link: "wallet" },
  {
    icon: IconBrandWhatsapp,
    label: "Contact",
    link: "https://wa.me/237620302414",
  },
  // { icon: IconUserCircle, label: "Logout", link: "logout" },
];
const authLinksMockdata = [
  { icon: IconMessageChatbot, label: "Chat", link: "chat" },
  { icon: IconRobot, label: "Features", link: "features" },
  {
    icon: IconBrandWhatsapp,
    label: "Contact",
    link: "https://wa.me/237620302414",
  },
];

export function Drawer({ styles }) {
  const { classes, cx } = useStyles();
  const [active, setActive] = useState("home");

  const navigate = useNavigate();
  const handleNavigate = (link, data = "") => {
    setActive(link);
    navigate(`/${link}`, { state: data });
    // navigate(`/${link}`);
  };

  const dispatch = useDispatch();
  const userAuthenticated = useSelector((state) => state.auth);

  const auth = getAuth();
  const onLogout = () => {
    auth.signOut();
    if (storageAvailable("localStorage")) localStorage.clear();

    dispatch(logout());
  };

  const mainLinks = mainLinksMockdata.map((link) => (
    <Tooltip
      label={link.label}
      position="right"
      withArrow
      transitionDuration={0}
      key={link.label}
      className={
        window.location.pathname === `/${active}`
          ? `${classes.activeIcon}`
          : classes.nonActive
      }
    >
      <FlexColumn
        justifyContent="flex-start"
        style={{
          // borderTop: "0.01px solid gray",
          borderBottom: "0.01px solid gray",
          cursor: "pointer",
        }}
      >
        {link.label === "Contact" ? (
          <a
            h
            href={link.link}
            style={{ color: "inherit", textDecoration: "inherit" }}
            target="_blank"
            rel="noreferrer"
          >
            <UnstyledButton
              onClick={() =>
                link.label === "Logout"
                  ? onLogout()
                  : handleNavigate(link.link, link.label)
              }
              className={cx(classes.mainLink, {
                [classes.mainLinkActive]: link.link === active,
              })}
            >
              <link.icon stroke={1.5} width={40} />
            </UnstyledButton>
            <Text size="xs">{link.label}</Text>
          </a>
        ) : (
          <>
            <UnstyledButton
              onClick={() =>
                link.label === "Logout"
                  ? onLogout()
                  : handleNavigate(link.link, link.label)
              }
              className={cx(classes.mainLink, {
                [classes.mainLinkActive]: link.link === active,
              })}
            >
              <link.icon stroke={1.5} width={40} />
            </UnstyledButton>
            <Text size="xs">{link.label}</Text>
          </>
        )}
      </FlexColumn>
    </Tooltip>
  ));
  const authLinks = authLinksMockdata.map((link) => (
    <Tooltip
      label={link.label}
      position="right"
      withArrow
      transitionDuration={0}
      key={link.label}
      className={
        window.location.pathname === `/${active}`
          ? `${classes.activeIcon}`
          : classes.nonActive
      }
    >
      <FlexColumn
        justifyContent="flex-start"
        style={{
          // borderTop: "0.01px solid gray",
          borderBottom: "0.01px solid gray",
          cursor: "pointer",
        }}
      >
        <UnstyledButton
          // onClick={
          //   link.link === "chat" && `/${link.link}` === window.location.pathname
          //     ? () => openSubMenu(link.link)
          //     : () => handleNavigate(link.link, link.label)
          // }
          onClick={() => handleNavigate(link.link, link.label)}
          className={cx(classes.mainLink, {
            [classes.mainLinkActive]: link.link === active,
          })}
        >
          <link.icon stroke={1.5} width={40} />
        </UnstyledButton>
        <Text size="xs">{link.label}</Text>
      </FlexColumn>
    </Tooltip>
  ));

  return (
    <Navbar height={"95vh"} width="auto" style={styles}>
      <Navbar.Section grow className={classes.wrapper}>
        <div className={classes.aside}>
          <div className={classes.logo}>
            <img width="60px" height="50%" src={Logo} alt="ChatGDA Logo" />
          </div>
          <Scrollbars>
            <div
              style={{
                maxHeight: "70vh",
                overflow: "scroll",
              }}
              className="oScrollBar"
            >
              {userAuthenticated ? mainLinks : authLinks}
            </div>
          </Scrollbars>
        </div>
      </Navbar.Section>
    </Navbar>
  );
}
