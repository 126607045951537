import React, { useEffect, createContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { auth } from "../services/firebaseConfig";
import { toggleAuth } from "../store/auth/authReducer";
import { readUser } from "../store/user/userJobs";
import { save } from "../store/user/userReducer";
import Cookies from "js-cookie";
import { Notifications } from "@mantine/notifications";
export const UserContext = createContext({ user: {} });
// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
  const storeUser = useSelector((state) => state.user);

  const dispatch = useDispatch();

  useEffect(() => {
    auth.onAuthStateChanged(
      function (user) {
        if (user) {
          // User is signed in.
          const {
            displayName,
            email,
            emailVerified,
            photoURL,
            uid,
            phoneNumber,
            providerData,
          } = user;
          const newUser = {
            displayName,
            email,
            emailVerified,
            photoURL,
            uid,
            phoneNumber,
            providerData,
          };
          user.getIdToken().then(function (accessToken) {
            readUser(newUser).then((res) => {
              dispatch(save(res));
              dispatch(toggleAuth(true));
              Cookies.set("userID", JSON.stringify(res), { expires: 7 });
            });
          });
        } else {
          dispatch(toggleAuth(false));
        }
      },
      function (error) {
        // console.log(error);
      }
    );
  }, [dispatch]);
  return (
    <UserContext.Provider value={storeUser}>
      <>
        {/* <Toaster position="top-center" reverseOrder={false} /> */}
        <Notifications position="bottom-center" />

        {props.children}
      </>
    </UserContext.Provider>
  );
};
