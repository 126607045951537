import Start from "./views/Start/Start";
import { Features } from "./views/Features/Features";
import Error from "./views/Error/Error";

const authRoutes = [
  {
    path: "/",
    component: <Start />,
  },
  {
    path: "*",
    component: <Start />,
  },
  {
    path: "/features",
    component: <Features />,
  },
  {
    path: "/activate",
    component: <Error page="EV" />,
  },
  {
    path: "/terms",
    component: <Error page="TC" />,
  },
];
export default authRoutes;
