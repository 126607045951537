import {
  Box,
  Button,
  Center,
  Group,
  LoadingOverlay,
  SegmentedControl,
  Text,
  TextInput,
} from "@mantine/core";
import React, { useState } from "react";
import { FlexColumn, FlexRow } from "./Flex";
import { formatAmount, formatAmountWithComma } from "../workers/KSeparator";
import { id } from "../utils/idGenerator";
import axios from "axios";
import { useToggle } from "@mantine/hooks";
import omLogo from "../assets/images/orange-money-logo.png";
import momoLogo from "../assets/images/mtn-mobile-money-cameroun.jpg";
import { errorNotify, loadingNotify } from "../header/components/Notify";
import { Timestamp, doc, setDoc } from "firebase/firestore";
import { db } from "../services/firebaseConfig";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";

const PaymentModal = ({ setPaying, tokensUsed }) => {
  const operators = [
    {
      id: 0,
      value: "CM_ORANGEMONEY",
      label: (
        <Center>
          <img width="25px" src={omLogo} alt="OM" />
          <Box ml={10}>Orange Money</Box>
        </Center>
      ),
    },
    {
      id: 1,
      value: "CM_MOBILEMONEY",
      label: (
        <Center>
          <img width="25px" alt="MOMO" src={momoLogo} />
          <Box ml={10}>Mobile Money</Box>
        </Center>
      ),
    },
  ];
  const [amt, setAmt] = useState("");
  const [phn, setPhn] = useState("");
  const [ccTotal, setCcTotal] = useState(0);
  const [operator, toggle] = useToggle([...operators]);
  const [loading, setLoading] = useState(false);
  const [button, setButton] = useState("Pay");

  function getUserLanguage() {
    const userLanguage = navigator.language || navigator.userLanguage;
    return userLanguage.split("-")[0];
  }

  const user = useSelector((state) => state.user);
  const messages = useSelector((state) => state.messages);

  const language = getUserLanguage();
  const refID = id();
  const paymentBody = {
    phonenumber: phn,
    amount: amt,
    service: window.env.REACT_APP_MONETBIL_API,
    // operator: operator.value,
    locale: language,
    // country: "CM",
    // currency: "XAF",
    // notify_url: "https:www.your-notify-url.com",
    payment_ref: `CHATGDA${refID}`,
  };

  const firebaseWork = async (transaction, newUser) => {
    await setDoc(doc(db, "TRANSACTIONS", transaction.id), {
      ...transaction,
    });
    await setDoc(doc(db, "USERS", newUser.uid), {
      ...newUser,
    });
    Cookies.remove("paymentID");
    window.location.reload();
  };

  const verifyPayment = async () => {
    // setLoading(true);
    let loaded = false;
    const paymentId = Cookies.get("paymentID");
    // console.log("Working too", paymentId);
    const now = new Date();

    // verifyPayment(data.paymentId).then((res) => {

    // });
    const transaction = {
      id: paymentBody.payment_ref,
      fiat: paymentBody.amount,
      cc: parseInt(paymentBody.amount / 0.02),
      type: "ChatCoinPurchase",
      user: user.uid,
      timestamp: Timestamp.fromDate(now),
    };
    const newUser = {
      ...user,
      tokens: user.tokens + parseInt(paymentBody.amount / 0.02),
      newHigh: user.tokens + parseInt(paymentBody.amount / 0.02) - tokensUsed,
    };

    const formData = new URLSearchParams();
    formData.append("paymentId", paymentId);

    const result = await axios.post(
      "https://api.monetbil.com/payment/v1/checkPayment",
      formData
    );

    // console.log(result);
    if (result.data.transaction === undefined) {
      errorNotify({
        preCode: "Payment Pending!",
        preMessage: `Dial your short code *126# or #150# to Complete the payment.`,
      });
    } else if (
      result.data.transaction.message === "COULD_NOT_PERFORM_TRANSACTION"
    ) {
      errorNotify({
        preCode: "Payment Timeout",
        preMessage: `Payment Failed. Please try again`,
      });
      Cookies.remove("paymentID");
      window.location.reload();
    } else if (result.data.transaction.message === "SUCCESSFUL") {
      loadingNotify({
        preCode: "Payment Successful",
        preMessage: `Converting ${result.data.transaction.amount || null} ${
          result.data.transaction.currency || null
        } to ChatCoins...`,
        state: loaded,
        postCode: "Conversion Complete",
        postMessage: "Congratulations, You have refilled your Chat Coins.",
      });
      firebaseWork(transaction, newUser);
      setLoading(false);
      setPaying(false);
    } else {
      errorNotify({
        preCode: "Payment Canceled",
        preMessage: "Payment Canceled.  Please Try Again",
      });
      Cookies.remove("paymentID");
      window.location.reload();
    }
    // if (result.data?.transaction?.status) {
    //   loadingNotify({
    //     preCode: "Payment Successful",
    //     preMessage: `Converting from ${
    //       result.data.transaction.currency || null
    //     } to ChatCoins...`,
    //     state: loaded,
    //     postCode: "Conversion Complete",
    //     postMessage: "Congratulations, You have refilled your Chat Coins.",
    //   });
    //   firebaseWork(transaction, newUser);
    //   setLoading(false);
    //   setPaying(false);
    // } else {
    //   errorNotify({
    //     preCode: "Payment Not Completed!",
    //     preMessage: result.data?.transaction?.message,
    //   });
    // }
    return result.data;
  };
  const initiatePayment = async () => {
    setLoading(true);
    const { data } = await axios.post(
      "https://api.monetbil.com/payment/v1/placePayment",
      JSON.stringify(paymentBody),
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    if (data.status === "REQUEST_ACCEPTED") {
      setButton("Verify Payment");
      setLoading(false);
      Cookies.set("paymentID", data.paymentId);
    } else {
      errorNotify({
        code: data.status,
        message: data.message,
      });
      // console.log("Payment Error:", data);
      setLoading(false);
      setButton("Pay");
    }

    // console.log("Payment Body:", paymentBody);
  };

  const handleButtonTap = () => {
    if (button === "Pay") {
      if (parseFloat(amt) > 99) {
        if (String(phn).length > 11) {
          initiatePayment();
        } else {
          errorNotify({
            code: "Verify Phone Number",
            message: `Make sure to add your country code WITHOUT the plus "+"`,
          });
        }
      } else {
        errorNotify({
          code: "Minimum order is 100XAF",
          message: `${amt}XAF is Lower than The Minimum ChatCoin Order`,
        });
      }
    } else {
      // console.log("working");
      verifyPayment();
    }
  };

  //CONVERSION RATE
  const getCCTotal = (e) => {
    setAmt(e.target.value);
    setCcTotal(parseInt(e.target.value / 0.02));
  };
  return (
    <>
      <Group position="apart">
        <LoadingOverlay
          visible={loading}
          overlayBlur={2}
          loaderProps={{ size: "sm", color: "violet", variant: "bars" }}
        />
        <FlexColumn>
          <Text align="center" fs={20} mb={15}>
            Add More Chat Coins
          </Text>
          <FlexColumn>
            <SegmentedControl
              data={[...operators]}
              transitionDuration={400}
              transitionTimingFunction="linear"
              color="violet"
              onChange={toggle}
              value={operator.value}
            />
          </FlexColumn>
          {operator.id === 0 ? (
            <FlexColumn>
              <FlexRow
                // justifyContent="flex-start"
                alignItems="flex-end"
                style={{
                  padding: "1em 0em",
                  width: "auto",
                  minWidth: "inherit",
                }}
              >
                <Text mr={10}>Amount:</Text>
                <TextInput
                  placeholder="500"
                  value={amt}
                  onChange={(e) => getCCTotal(e)}
                  minLength={3}
                  style={{ width: "auto", minWidth: "100px" }}
                />
                <FlexColumn
                  justifyContent="flex-start"
                  width="auto"
                  height="30px"
                >
                  <Text fw={600} fz="9px">
                    XAF
                  </Text>
                </FlexColumn>
                <FlexRow justifyContent="flex-start" alignItems="flex-start">
                  <Text mr={15} ml={15}>
                    =
                  </Text>
                  <Text fw={700} fz="xl">
                    {ccTotal < 100000
                      ? formatAmountWithComma(ccTotal)
                      : formatAmount(ccTotal)}
                  </Text>
                  <Text fw={600} fz="9px">
                    CC
                  </Text>
                </FlexRow>
              </FlexRow>
              <FlexRow
                justifyContent="flex-start"
                alignItems="flex-end"
                style={{
                  padding: "1em 0em",
                }}
              >
                <Text mr={10}>Mobile:</Text>
                <TextInput
                  placeholder={"23769XXXXXXX"}
                  value={phn}
                  minLength={12}
                  onChange={(e) => setPhn(e.target.value)}
                />
              </FlexRow>
            </FlexColumn>
          ) : (
            <FlexColumn>
              <FlexRow
                justifyContent="flex-start"
                alignItems="flex-end"
                style={{ padding: "1em 0em" }}
              >
                <Text mr={10}>Amount:</Text>
                <TextInput
                  placeholder="500"
                  value={amt}
                  onChange={(e) => getCCTotal(e)}
                  minLength={3}
                  style={{ width: "auto", minWidth: "100px" }}
                />
                <FlexColumn
                  justifyContent="flex-start"
                  width="auto"
                  height="30px"
                >
                  <Text fw={600} fz="9px">
                    XAF
                  </Text>
                </FlexColumn>
                <FlexRow justifyContent="flex-start" alignItems="flex-start">
                  <Text mr={15} ml={15}>
                    =
                  </Text>
                  <Text fw={700} fz="xl">
                    {ccTotal < 100000
                      ? formatAmountWithComma(ccTotal)
                      : formatAmount(ccTotal)}
                  </Text>
                  <Text fw={600} fz="9px">
                    CC
                  </Text>
                </FlexRow>
              </FlexRow>
              <FlexRow
                justifyContent="flex-start"
                alignItems="flex-end"
                style={{ padding: "1em 0em" }}
              >
                <Text mr={10}>Mobile:</Text>
                <TextInput
                  placeholder={"23767XXXXXXX"}
                  value={phn}
                  minLength={13}
                  onChange={(e) => setPhn(e.target.value)}
                />
              </FlexRow>
            </FlexColumn>
          )}
        </FlexColumn>
      </Group>
      <FlexColumn>
        <Button
          variant="filled"
          color="violet"
          fullWidth
          onClick={() => handleButtonTap()}
        >
          {button}
        </Button>
      </FlexColumn>
    </>
  );
};

export default PaymentModal;
