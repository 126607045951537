import React from "react";
import { Text } from "@mantine/core";

const Terms = () => {
  // Suggested terms and conditions
  const suggestedTerms = [
    "Users must agree to abide by both GDA's ChatGDA and Open A.I's ChatGPT rules and guidelines.",
    "Any misuse or abusive behavior will result in immediate suspension of the account.",
    "The platform reserves the right to monitor and moderate user interactions.",
    "Users are responsible for the content they generate while using the platform.",
    "Personal information shared on the platform is subject to the privacy policy.",
    "The platform cannot guarantee the accuracy or reliability of generated responses.",
    "The platform is friendly for Everyone and comes with customization to suit specified age groups.",
    "Commercial use of the platform's services requires prior permission.",
    "The platform is not liable for any damages or losses incurred by users.",
    "By using the platform, users agree to these terms and conditions.",
  ];

  return (
    <div style={{ margin: "auto" }}>
      <h1 style={{ marginTop: "1em" }}>Terms and Conditions</h1>
      <Text>
        Please read the following terms and conditions before using the
        AI-powered chat platform:
      </Text>
      <ul>
        {suggestedTerms.map((term, index) => (
          <li key={index} style={{ textAlign: "left", margin: "10px" }}>
            {term}
          </li>
        ))}
      </ul>
      <Text>
        By accessing or using the platform, you acknowledge that you have read,
        understood, and agree to be bound by these terms and conditions.
      </Text>
      <Text mb="lg">
        If you do not agree to these terms and conditions, please refrain from
        using the platform or its services.
      </Text>
    </div>
  );
};

export default Terms;
