import { upperFirst } from "@mantine/hooks";
import {
  TextInput,
  Text,
  Paper,
  Group,
  Divider,
  Checkbox,
  Anchor,
  Stack,
  Button,
} from "@mantine/core";
import { IconBrandGoogle } from "@tabler/icons-react";
import TextTyping from "../../components/TypingText";
import { FlexRow } from "../../components/Flex";

import "./Auth.css";

import {
  GoogleAuthProvider,
  getAuth,
  sendSignInLinkToEmail,
  signInWithPopup,
} from "firebase/auth";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import getRandomErrorMessage from "../../workers/errorMessage";
import { useDispatch } from "react-redux";
import { save } from "../../store/user/userReducer";
import {
  errorNotify,
  loadingNotify,
  successNotify,
} from "../../header/components/Notify";
import { readUser } from "../../store/user/userJobs";
import { toggleAuth } from "../../store/auth/authReducer";
import Cookies from "js-cookie";

export function Login(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const auth = getAuth();
  const googleProvider = new GoogleAuthProvider();
  const signInWithGoogle = async () => {
    await signInWithPopup(auth, googleProvider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // console.log("User Token: ", token);
        // The signed-in user info.
        const user = result.user;
        const { displayName, email, emailVerified, photoURL, uid } = user;
        const currUser = {
          displayName,
          email,
          emailVerified,
          photoURL,
          uid,
        };
        readUser(currUser).then((res) => {
          dispatch(save(res));
          dispatch(toggleAuth(true));
          Cookies.set("userID", JSON.stringify(res), { expires: 7 });
        });
        successNotify({
          message: `Hurray!! Welcome to ChatGDA, ${
            currUser?.displayName || null
          }`,
          code: "Login Successful",
        });
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        console.log("Sign In Error", errorCode);
        const errorMessage = error.message;
        console.log("Sign In Error Message", errorMessage);
        // The email of the user's account used.
        // const email = error.customData.email;
        // console.log("Sign In Error", email);
        errorNotify({
          code: `Error - ${errorCode}`,
          message: errorMessage,
        });

        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...

        // console.log("Credentials: ", credential);
      });
  };

  const googleAuth = () => {
    setWorking(true);
    signInWithGoogle();
  };
  const [email, setEmail] = useState("");
  const [terms, setTerms] = useState(true);
  const [working, setWorking] = useState(false);

  const handleLogin = async () => {
    if (email.length) {
      setWorking(true);
      loadingNotify({
        preCode: "Sending Link",
        preMessage: "Sending Activation Link to Provided Email",
        state: working,
        postCode: "Link Sent",
        postMessage: "Check Your Email for Activation Link",
      });
      const actionCodeSettings = {
        url: "http://192.168.216.176:3000/activate", // The URL where the user will be redirected after clicking the email link
        handleCodeInApp: true,
      };
      await sendSignInLinkToEmail(auth, email, actionCodeSettings)
        .then(() => {
          // The link was successfully sent. Inform the user.
          // Save the email locally so you don't need to ask the user for it again
          // if they open the link on the same device.
          Cookies.set("emailForSignIn", email);
          setWorking(false);
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          // console.log("Login Error Msg", errorMessage);
          errorNotify({
            message: errorMessage,
            code: `${errorCode} - Something went wrong...`,
          });
        });
    } else {
      const errorMessage = getRandomErrorMessage();

      errorNotify({
        message: errorMessage,
        code: "Email Error",
      });
    }
  };

  return (
    <Paper radius="md" p="xl" withBorder {...props} className="authBox">
      <Text size="lg" weight={500}>
        <TextTyping
          rawText={`Welcome to ChatGDA, Connect with
`}
        />
      </Text>

      <Group grow mb="md" mt="md">
        <FlexRow width="40%">
          <Button
            variant="outline"
            style={{
              display: "flex",
              flexDirection: "row",
            }}
            color="violet"
            onClick={googleAuth}
          >
            <IconBrandGoogle
              width={"30px"}
              style={{
                cursor: "pointer",
              }}
            />
            {/* <TextTyping rawText={"Google"} /> */}
            <Text
              style={{
                cursor: "pointer",
                textDecoration: "underline",
              }}
            >
              Google
            </Text>
          </Button>
        </FlexRow>
        {/* <FlexRow width="40%" style={{ cursor: "pointer" }}>
          <IconBrandTwitter width={"30px"} />
          <TextTyping rawText={"Twitter"} />
          <Text>Twitter</Text>
        </FlexRow> */}
      </Group>

      <Divider label="or Connect with Email" labelPosition="center" my="lg" />

      <Stack>
        {/* {type === "register" && (
            <div>
              <Text
                style={{
                  textAlign: "left",
                }}
              >
                Name:
              </Text>
              <TextInput
                placeholder="Your name"
                value={form.values.name}
                color="violet"
                onChange={(event) =>
                  form.setFieldValue("name", event.currentTarget.value)
                }
                radius="md"
              />
            </div>
          )} */}
        <div>
          <TextInput
            required
            placeholder="example@gdaafrica.com"
            value={email}
            color="violet"
            onChange={(event) => setEmail(event.target.value)}
            error={
              email.length && !email.includes("@") && !email.includes(".")
                ? `Email must include "@" & "."`
                : null
            }
            radius="md"
            onKeyUp={(e) => {
              if (e.key.match("Enter")) {
                if (email.length) {
                  if (email.includes("@") && email.includes(".")) handleLogin();
                } else {
                  const msg = getRandomErrorMessage();
                  errorNotify({
                    message: msg,
                    code: "Email Error",
                  });
                }
              }
            }}
          />
        </div>
        {/* <div>
            <Text
              style={{
                textAlign: "left",
              }}
            >
              Password:
            </Text>
            <PasswordInput
              required
              placeholder="Your password"
              value={form.values.password}
              color="violet"
              onChange={(event) =>
                form.setFieldValue("password", event.currentTarget.value)
              }
              error={
                form.errors.password &&
                "Password should include at least 6 characters"
              }
              radius="md"
            />
          </div> */}

        <Checkbox
          label={
            <Anchor
              component="button"
              type="button"
              color="dimmed"
              onClick={() => navigate("/terms")}
              size="xs"
            >
              I accept GDA's terms and conditions
            </Anchor>
          }
          checked={terms}
          color="violet"
          onChange={(event) => setTerms(!terms)}
        />
      </Stack>

      <Group position="apart" mt="xl">
        {/* <Anchor
            component="button"
            type="button"
            color="dimmed"
            onClick={() => toggle()}
            size="xs"
          >
            {type === "register"
              ? "Already have an account? Login"
              : "Don't have an account? Register"}
          </Anchor> */}
        <Button
          type="submit"
          radius="xl"
          color="violet"
          style={{
            background: "../../assets/Onitsha.gif",
            backgroundSize: "50%",
          }}
          fullWidth
          onClick={() => handleLogin()}
          disabled={
            (email.length && (!email.includes("@") || !email.includes("."))) ||
            !terms
          }
          loading={working}
        >
          {working ? upperFirst("connecting...") : upperFirst("connect")}
        </Button>
      </Group>
    </Paper>
  );
}
