import { configureStore } from "@reduxjs/toolkit";
import chatsReducer from "./chats/chatsReducer";
import userReducer from "./user/userReducer";
import activeChatReducer from "./activeChat/activeChatReducer";
import subscriptionReducer from "./subscription/subscriptionReducer";
import transactionsReducer from "./transactions/transactionsReducer";
import settingsReducer from "./settings/settingsReducer";
import promptReducer from "./prompt/promptReducer";
import authReducer from "./auth/authReducer";
import messagesReducer from "./messages/messagesReducer";
// import counterReducer from "../features/counter/counterSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    chats: chatsReducer,
    activeChat: activeChatReducer,
    user: userReducer,
    subscription: subscriptionReducer,
    transactions: transactionsReducer,
    settings: settingsReducer,
    prompt: promptReducer,
    messages: messagesReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});
