import { useState, useEffect } from "react";

const TextTyping = ({ rawText }) => {
  const [text, setText] = useState("");
  const [fullText] = useState(rawText);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (index < fullText.length) {
      setTimeout(() => {
        setText(text + fullText[index]);
        setIndex(index + 1);
        // setLogoState(!logoState);
      }, 40);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index, fullText]);

  return text;
};

export default TextTyping;
