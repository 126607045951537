import { Loader } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { IconCheck, IconX } from "@tabler/icons-react";

export const errorNotify = ({ message = "", code = "", icon = <IconX /> }) =>
  notifications.show({
    withCloseButton: true,
    autoClose: 5000,
    title: code || null,
    message: message,
    color: "red",
    icon: icon,
    style: { border: "0.5px solid #f2e0ff" },
  });

export const successNotify = ({
  message = "",
  code = "",
  icon = <IconCheck />,
}) =>
  notifications.show({
    message: message,
    withCloseButton: true,
    autoClose: 3000,
    title: code,
    color: "green",
    icon: icon,
    style: { border: "0.5px solid #f2e0ff" },
  });

export const loadingNotify = ({
  preMessage = "",
  postMessage = "",
  postCode = "",
  preCode = "",
  state = false,
}) => {
  notifications.show({
    id: "load-data",
    message: preMessage,
    withCloseButton: false,
    title: preCode,
    color: "green",
    style: { border: "0.5px solid #f2e0ff" },
    loading: state,
    autoClose: false,
    icon: <Loader />,
  });

  setTimeout(() => {
    notifications.update({
      id: "load-data",
      color: "teal",
      title: postCode,
      message: postMessage,
      icon: <IconCheck size="1rem" />,
      autoClose: 2000,
    });
  }, 3000);
};
