import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import remarkHtml from "remark-html";
// import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";
import remarkParse from "remark-parse/lib";
import remarkSlug from "remark-slug";
import remarkToc from "remark-toc";
import remarkMath from "remark-math";
import remarkFootnotes from "remark-footnotes";
import { Prism } from "@mantine/prism";
import SyntaxHighlighter from "react-syntax-highlighter/dist/esm/default-highlight";
import { FlexColumn } from "./Flex";
import { Text } from "@mantine/core";

const CustomCodeBlock = (props) => {
  // console.log("PROPS", props);
  // function getRandomTheme() {
  //   var themes = [
  //     base16AteliersulphurpoolLight,
  //     atomDark,
  //     a11yDark,
  //     dracula,
  //     oneDark,
  //     vscDarkPlus,
  //     gruvboxDark,
  //     duotoneLight,
  //     materialDark,
  //     cb,
  //     materialLight,
  //     materialOceanic,
  //     duotoneSpace,
  //   ];
  //   var randomIndex = Math.floor(Math.random() * themes.length);
  //   return themes[randomIndex];
  // }

  // function findLanguageKey(longString) {
  //   // console.log("Long", longString);

  //   const languageTable = {
  //     "```javascript\n": "javascript",
  //     "```python\n": "python",
  //     "```java\n": "java",
  //     "```cpp\n": "cpp",
  //     "```csharp\n": "csharp",
  //     "```php\n": "php",
  //     "```ruby\n": "ruby",
  //     "```swift\n": "swift",
  //     "```typescript\n": "typescript",
  //     "```kotlin\n": "kotlin",
  //     "```go\n": "go",
  //     "```rust\n": "rust",
  //     "```matlab\n": "matlab",
  //     "```shell\n": "shell",
  //     "```css\n": "css",
  //     "```sass\n": "sass",
  //     "```html\n": "html",
  //     "```jsx\n": "jsx",
  //     "```dart\n": "dart",
  //   };
  //   for (const key in languageTable) {
  //     if (String(longString).includes(languageTable[key])) {
  //       // console.log("Language", languageTable[key]);
  //       return String(languageTable[key]);
  //     }
  //   }

  //   // console.log("Lang Not Found");
  // }

  return (
    // <SyntaxHighlighter
    //   PreTag="pre"
    //   style={vscDarkPlus}
    //   language={findLanguageKey(props.children)}
    //   {...props}
    //   children={String(props.children).replace(/\n$/, "")}
    // >
    //   {String(props.children).replace(/\n$/, "")}
    // </SyntaxHighlighter>

    <Prism
      language={props?.className?.replace("language-", "")}
      withLineNumbers
      children={String(props.children).replace(/\n$/, "")}
      {...props}
    >
      {String(props.children).replace(/\n$/, "")}
    </Prism>
  );
};
const CustomPBlock = (props) => {
  return (
    <FlexColumn {...props} alignItems="flex-start" style={{ lineHeight: 2 }}>
      {props.children}
    </FlexColumn>
  );
};

const MarkdownRenderer = ({ markdown }) => {
  return (
    <ReactMarkdown
      remarkPlugins={[
        remarkGfm,
        remarkHtml,
        remarkParse,
        remarkSlug,
        remarkToc,
        remarkMath,
        // remarkAbbr,
        remarkFootnotes,
      ]}
      children={markdown.replaceAll(/\\n/gi, "\n")}
      components={{
        code: (props) => CustomCodeBlock(props),
        p: (props) => CustomPBlock(props),
      }}
    ></ReactMarkdown>
  );
};

export default MarkdownRenderer;
