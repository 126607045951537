import {
  createStyles,
  Title,
  SimpleGrid,
  Text,
  Button,
  ThemeIcon,
  Grid,
  Col,
} from "@mantine/core";
import {
  IconReceiptOff,
  IconFlame,
  IconCircleDotted,
  IconFileCode,
  IconCoin,
} from "@tabler/icons-react";
import View from "../View";
import { FlexColumn, FlexRow } from "../../components/Flex";
import "./Features.css";
import TextTyping from "../../components/TypingText";
import travel from "../../utils/travel";
import { useNavigate } from "react-router-dom";

const useStyles = createStyles((theme) => ({
  wrapper: {
    height: "inherit",
    padding: "1em, 0em",
    alignItems: "center",
    overflowX: "hidden",
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontSize: 36,
    fontWeight: 900,
    lineHeight: 1.1,
    marginBottom: theme.spacing.md,
    marginTop: theme.spacing.xl * 2,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,

    [theme.fn.smallerThan("sm")]: {
      fontSize: 32,
      marginTop: theme.spacing.xl,
    },
  },
}));

const features = [
  {
    icon: IconCoin,
    title: "Free Demo Use",
    description:
      "All of ChatGDA's features are available to use for everyone as new users are awarded with a free welcome ChatCoins. ChatCoins are also valuable to purchase digital items on the Upcoming NFT Marketplace",
  },
  {
    icon: IconFileCode,
    title: "Leading Technology",
    description:
      "ChatGDA is built on the latest and most advanced technology in the field of Natural Language Processing. The platform also uses blockchain technology to ensure the security of your data and perform transactions.",
  },
  {
    icon: IconCircleDotted,
    title: "Cross Platform",
    description:
      "ChatGDA is Built to Support a wide variety of screens and devices ranging from Mobile Phones (Android, iOS and More) to desktop and laptop computers (Windows, MacOs, ChromeOS, Linux and More).",
  },
  {
    icon: IconFlame,
    title: "Lightweight, Light Data Usage",
    description:
      "ChatGDA is Lightweight so it runs on low performant devices and needs little connectivity. It was built to be an offline first Web App, easily installable through the web on devices to serve like a Native App.",
  },
];

export function Features() {
  const { classes } = useStyles();

  const navigate = useNavigate();

  const items = features.map((feature) => (
    <div key={feature.title}>
      <ThemeIcon
        size={44}
        radius="md"
        variant="gradient"
        gradient={{ deg: 133, from: "indigo", to: "purple" }}
      >
        <feature.icon size={26} stroke={1.5} />
      </ThemeIcon>
      <Text fz="lg" mt="sm" fw={500} style={{ textDecoration: "underline" }}>
        {feature.title}
      </Text>
      <Text c="dimmed" fz="sm" mt="xs">
        {feature.description}
      </Text>
      <br />
    </div>
  ));

  return (
    <View link={"/features"}>
      <FlexColumn
        style={{
          height: "100%",
          margin: "auto",
          alignItems: "center",
        }}
      >
        <FlexRow
          style={{
            width: "inherit",
            padding: "1em",
            height: "inherit",
          }}
        >
          <div className={classes.wrapper}>
            <Grid gutter={80}>
              <Col span={12} md={5}>
                <Title className={classes.title} order={2}>
                  ChatGDA is A full featured A.I Based Chat Assistant
                </Title>
                <Text c="dimmed">
                  <TextTyping
                    rawText={
                      "ChatGDA allows you to Build, Create, Innovate, Learn and Also Earn. With a knowledge base trained on the ChatGPT Model, its implementations are limitless."
                    }
                  />
                </Text>

                <Button
                  variant="gradient"
                  gradient={{ deg: 133, from: "indigo", to: "purple" }}
                  size="lg"
                  radius="md"
                  mt="xl"
                  onClick={() => travel(navigate, "/chat", {})}
                >
                  Get started
                </Button>
              </Col>
              <Col span={12} md={7}>
                <SimpleGrid
                  cols={2}
                  spacing={30}
                  breakpoints={[{ maxWidth: "md", cols: 1 }]}
                >
                  {items}
                </SimpleGrid>
              </Col>
            </Grid>
          </div>
        </FlexRow>
      </FlexColumn>
    </View>
  );
}
