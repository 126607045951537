import { createSlice } from "@reduxjs/toolkit";

export const messagesSlice = createSlice({
  name: "messages",
  initialState: null,
  reducers: {
    saveMessage: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state = action.payload?.length ? [...action.payload] : [];
      return state;
    },
    addMessage: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state = state?.length ? [...state, action.payload] : [action.payload];
      return state;
    },
    // decremented: (state) => {
    //   state.value -= 1;
    // },
  },
});

export const { saveMessage, addMessage } = messagesSlice.actions;
export default messagesSlice.reducer;
