import React, { useState } from "react";
import View from "../View";
import { FlexColumn, FlexRow } from "../../components/Flex";
import { NotFound } from "./404";
import { ComingSoon } from "./ComingSoon";
import { EmailVerification } from "../Auth/EmailVerification";
import Terms from "../Terms/Terms";

const Error = ({ page }) => {
  const [state] = useState(page);
  return (
    <View link={window.location.pathname}>
      <FlexColumn
        style={{
          height: "inherit",
          margin: "auto",
          alignItems: "center",
          overFlowX: "scroll",
        }}
      >
        <FlexRow
          style={{
            width: "100%",
            height: "inherit",
          }}
        >
          {state === "404" ? (
            <NotFound />
          ) : state === "CS" ? (
            <ComingSoon />
          ) : state === "EV" ? (
            <EmailVerification />
          ) : (
            <Terms />
          )}
        </FlexRow>
      </FlexColumn>
    </View>
  );
};

export default Error;
