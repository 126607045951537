import {
  ActionIcon,
  Button,
  Dialog,
  Group,
  Menu,
  Text,
  Transition,
  createStyles,
} from "@mantine/core";
import { loadingNotify, successNotify } from "./Notify";
import { FlexColumn, FlexRow } from "../../components/Flex";
import { Toaster } from "react-hot-toast";
import { IconCopy, IconMessageShare, IconNote } from "@tabler/icons-react";
import { IconTrash } from "@tabler/icons-react";
import { formatDateTime } from "../../utils/formatDate";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useDisclosure } from "@mantine/hooks";
import { editMessage } from "../../store/messages/messageJobs";
import MarkdownRenderer from "../../components/MarkdownRenderer";
import fixString from "../../utils/fixString";

const Message = ({ mainMessage, index, styles }) => {
  const useStyles = createStyles((theme) => ({
    wrapper: {
      paddingLeft: theme.spacing.xs,
      paddingRight: theme.spacing.xs,
      borderRadius: theme.radius.sm,
      font: theme.fontSizes.sm,

      [theme.fn.smallerThan("sm")]: {
        flexDirection: "column",
      },
    },
    dot: {
      width: "20px",
      height: "10px",
      borderRadius: theme.radius.md,
      marginRight: "5px",
      marginTop: "1px",
      backgroundColor: theme.colorScheme === "dark" ? "#f2e0ff" : "indigo",
      border: `${"1px"} solid ${
        theme.colorScheme === "dark" ? "#f2e0ff" : "indigo"
      }`,
    },
    mDot: {
      width: "15px",
      height: "7.5px",
      borderRadius: theme.radius.md,
      marginRight: "3px",
      marginTop: "1px",
      backgroundColor: theme.colorScheme === "dark" ? "#f2e0ff" : "indigo",
      border: `${"1px"} solid ${
        theme.colorScheme === "dark" ? "#f2e0ff" : "indigo"
      }`,
    },
    message: {
      height: "auto",
      padding: theme.spacing.xs,
      borderRadius: theme.radius.lg,
      backgroundColor: theme.colorScheme === "light" ? "indigo" : "#f2e0ff",
      border: `${"1px"} solid ${
        theme.colorScheme === "light" ? "#f2e0ff" : "indigo"
      }`,
      color: theme.colorScheme === "light" ? "white" : "black",
      maxWidth: "70vw !important",
      width: "auto",
      overflowClipBox: "padding-box",
      overflowX: "clip !important",
      flexWrap: "wrap",
    },
    botDot: {
      width: "20px",
      height: "10px",
      borderRadius: theme.radius.md,
      marginLeft: "5px",
      marginTop: "1px",
      backgroundColor: theme.colorScheme === "dark" ? "#f2e0ff" : "indigo",
      border: `${"1px"} solid ${
        theme.colorScheme === "dark" ? "#f2e0ff" : "indigo"
      }`,
    },
    botMDot: {
      width: "15px",
      height: "7.5px",
      borderRadius: theme.radius.md,
      marginLeft: "3px",
      marginTop: "1px",
      backgroundColor: theme.colorScheme === "dark" ? "#f2e0ff" : "indigo",
      border: `${"1px"} solid ${
        theme.colorScheme === "dark" ? "#f2e0ff" : "indigo"
      }`,
    },
    botMessage: {
      height: "auto",
      padding: theme.spacing.xs,
      borderRadius: theme.radius.lg,
      backgroundColor: theme.colorScheme === "dark" ? "indigo" : "#f2e0ff ",
      border: `${"1px"} solid ${
        theme.colorScheme === "dark" ? "#f2e0ff" : "indigo"
      }`,
      width: "auto",
      maxWidth: "80vw !important",
      overflowClipBox: "padding-box",
      overflowX: "clip !important",
      flexWrap: "wrap",
    },
    botContent: {
      width: "auto !important",
    },
  }));
  const messages = mainMessage?.messages;
  const { classes } = useStyles();
  const notify = () => {
    successNotify({
      code: "Copied!",
      message: "Text Copied Successfully",
    });
  };
  const [opened, { toggle, close }] = useDisclosure(false);

  const notifyDelete = (message) => {
    let loaded = false;
    loadingNotify({
      state: loaded,
      preCode: "Deleting...",
      preMessage: "Deleting message pair..",
      postCode: "Deleted!",
      postMessage: "Message Successfully Deleted Forever!",
    });
    let temp = { ...message, isDeleted: true };
    editMessage(temp).then((res) => {
      loaded = true;
      close();
      window.location.reload();
    });
  };

  // const convertMarkdown = (text) => {
  //   const file = text;
  //   // const file = readSync(text);

  //   unified()
  //     .use(remarkParse)
  //     .use(remarkHighlightjs)
  //     .use(remarkHtml)
  //     .process(file)
  //     .then((file) => {
  //       return String(file).replace(/\n$/, "");
  //     });
  // };

  // //REGEX
  // const regexLine = /\n/gi;
  // const regexUnderS = /_/gi;
  // // userName.replaceAll(regexLine, "<br />");

  return (
    <FlexColumn style={{ ...styles, marginTop: "1em", paddingBottom: "1em" }}>
      <Dialog
        opened={opened}
        onClose={close}
        size="lg"
        radius="md"
        withCloseButton
        transition="slide-up"
        transitionDuration={300}
        transitionTimingFunction="ease"
      >
        <Text size="sm" mb="xs" weight={500}>
          Deleting will delete both the Message and its Response. Delete both?
        </Text>

        <Group align="flex-center">
          <Button onClick={() => notifyDelete(mainMessage)} bg="red">
            Delete
          </Button>
        </Group>
      </Dialog>
      <Text size="8px">
        {mainMessage.timestamp?.seconds
          ? formatDateTime(mainMessage.timestamp)
          : mainMessage.timestamp}
      </Text>
      {messages?.map((message) =>
        message.role === "user" ? (
          <Transition
            mounted={true}
            transition="slide-up"
            duration={300}
            timingFunction="ease"
          >
            {(styles) => (
              <FlexColumn
                className={classes.wrapper}
                alignItems="flex-end"
                width="100%"
                style={styles}
              >
                <Text size="8px" style={{ marginRight: "1.5em" }}>
                  {message.name.replaceAll(/_/gi, " ")}
                </Text>
                <FlexRow maxWidth="70%" justifyContent="flex-end">
                  <Group
                    spacing={0}
                    position="right"
                    style={{ cursor: "pointer" }}
                  >
                    <Menu
                      // transitionProps={{ transition: "pop" }}
                      withArrow
                      position="bottom-end"
                      withinPortal
                    >
                      <Menu.Target>
                        <ActionIcon>
                          <IconMessageShare size="20px" />
                        </ActionIcon>
                      </Menu.Target>
                      <Menu.Dropdown>
                        <Menu.Item icon={<IconCopy size="1rem" stroke={1.5} />}>
                          <CopyToClipboard
                            text={fixString(JSON.parse(message.content))}
                            onCopy={notify}
                          >
                            <FlexColumn alignItems="flex-start">
                              <Text>Copy Message</Text>
                            </FlexColumn>
                          </CopyToClipboard>
                        </Menu.Item>
                        <Menu.Item icon={<IconNote size="1rem" stroke={1.5} />}>
                          Create Note
                        </Menu.Item>
                        <Menu.Item
                          icon={<IconTrash size="1rem" stroke={1.5} />}
                          color="red"
                          onClick={toggle}
                        >
                          Delete Message Pair
                        </Menu.Item>
                      </Menu.Dropdown>
                    </Menu>
                  </Group>
                  <FlexColumn
                    className={classes.message}
                    alignItems="flex-end"
                    justifyContent="flex-start"
                    width="auto"
                    maxWidth="75%"
                    style={{
                      textAlign: "left",
                      fontSize: "11px",
                    }}
                  >
                    {/* {message.content.replaceAll(/\\n/gi, "<br />")} */}
                    <MarkdownRenderer
                      key={index}
                      markdown={JSON.parse(message.content)}
                    />
                    <Text size="8px">
                      {message.timestamp?.seconds
                        ? formatDateTime(message.timestamp)
                        : message.timestamp}
                    </Text>
                  </FlexColumn>
                </FlexRow>

                <div className={classes.dot}></div>
                <div className={classes.mDot}></div>
              </FlexColumn>
            )}
          </Transition>
        ) : null
      )}
      {messages.map((message) =>
        message.role === "assistant" ? (
          <Transition
            mounted={true}
            transition="slide-up"
            duration={300}
            timingFunction="ease"
          >
            {(styles) => (
              <FlexColumn
                className={classes.wrapper}
                alignItems="flex-start"
                width="100%"
                style={styles}
              >
                <Toaster position="top-center" reverseOrder={false} />
                <Text size="8px" style={{ marginLeft: "1.5em" }}>
                  {message.name}
                </Text>
                <FlexRow maxWidth="100%" justifyContent="flex-start">
                  <FlexColumn
                    className={classes.botMessage}
                    alignItems="flex-start"
                    style={{ textAlign: "left", fontSize: "12px" }}
                  >
                    <MarkdownRenderer
                      key={index}
                      markdown={JSON.parse(message.content)}
                    />
                    {/* <BotMessage content={message.content} /> */}
                  </FlexColumn>
                  <Group
                    spacing={0}
                    position="right"
                    style={{ cursor: "pointer" }}
                  >
                    {/* <ActionIcon>
            <IconPencil size="1rem" stroke={1.5} />
          </ActionIcon> */}
                    <Menu
                      // transitionProps={{ transition: "pop" }}
                      withArrow
                      position="bottom-end"
                      withinPortal
                    >
                      <Menu.Target>
                        <ActionIcon>
                          <IconMessageShare size="20px" />
                        </ActionIcon>
                      </Menu.Target>
                      <Menu.Dropdown>
                        <Menu.Item icon={<IconCopy size="1rem" stroke={1.5} />}>
                          <CopyToClipboard
                            text={fixString(JSON.parse(message.content))}
                            onCopy={notify}
                          >
                            <FlexColumn alignItems="flex-start">
                              <Text>Copy Message</Text>
                            </FlexColumn>
                          </CopyToClipboard>
                        </Menu.Item>
                        <Menu.Item icon={<IconNote size="1rem" stroke={1.5} />}>
                          Create Note
                        </Menu.Item>
                        <Menu.Item
                          icon={<IconTrash size="1rem" stroke={1.5} />}
                          color="red"
                          onClick={toggle}
                        >
                          Delete Message Pair
                        </Menu.Item>
                      </Menu.Dropdown>
                    </Menu>
                  </Group>
                </FlexRow>
                <div className={classes.botDot}></div>
                <div className={classes.botMDot}></div>
              </FlexColumn>
            )}
          </Transition>
        ) : null
      )}
    </FlexColumn>
  );
};

export default Message;
