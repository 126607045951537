import {
  createStyles,
  Text,
  Title,
  TextInput,
  Button,
  Divider,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  getAuth,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from "firebase/auth";
import TextTyping from "../../components/TypingText";
import getRandomErrorMessage from "../../workers/errorMessage";
import { useDispatch } from "react-redux";
import { save } from "../../store/user/userReducer";
import storageAvailable from "../../utils/storageAvailable";
import { errorNotify, successNotify } from "../../header/components/Notify";
import { readUser } from "../../store/user/userJobs";
import Cookies from "js-cookie";
import { toggleAuth } from "../../store/auth/authReducer";

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
    padding: `calc(${theme.spacing.xl} * 2)`,
    borderRadius: theme.radius.md,
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.white,
    border: `${"1px"} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.colors.gray[3]
    }`,

    [theme.fn.smallerThan("sm")]: {
      flexDirection: "column-reverse",
      padding: theme.spacing.xl,
    },
    height: "85vh",
    justifyContent: "center",
  },

  image: {
    maxWidth: "40%",

    [theme.fn.smallerThan("sm")]: {
      maxWidth: "100%",
    },
  },

  body: {
    // paddingRight: `calc(${theme.spacing.xl} * 4)`,

    [theme.fn.smallerThan("sm")]: {
      //   paddingRight: 0,
      marginTop: theme.spacing.xl,
    },
    padding: "0em 1em 0em 1em",
  },

  title: {
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    lineHeight: 1,
    marginBottom: theme.spacing.md,
  },

  controls: {
    display: "flex",
    marginTop: theme.spacing.xl,
  },

  inputWrapper: {
    width: "100%",
    flex: "1",
  },

  input: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRight: 0,
  },

  control: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
}));

export function EmailVerification() {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const auth = getAuth();
  const [uEmail, setUEmail] = useState("");
  const [working, setWorking] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      let email = Cookies.get("emailForSignIn");
      if (email?.length) {
        setWorking(true);
        signInWithEmailLink(auth, email, window.location.href)
          .then((result) => {
            const user = result.user;
            const { displayName, email, emailVerified, photoURL, uid } = user;
            const currUser = {
              displayName: displayName || "",
              email,
              emailVerified,
              photoURL,
              uid,
            };
            // console.log("User: ", currUser);
            readUser({ ...currUser }).then((res) => {
              dispatch(save(res));
              dispatch(toggleAuth(true));
            });
            successNotify({
              message: `Hurray!! Welcome to ChatGDA, ${
                currUser.displayName || null
              }`,
              code: "Data Refreshed",
            });
            window.location.reload();
          })
          .catch((error) => {
            console.log("Email Verification Error", error.message);

            // Some error occurred, you can inspect the code: error.code
            // Common errors could be invalid email and invalid or expired OTPs.
          });
      }
      // The client SDK will parse the code from the link for you.
    }
  }, [auth, dispatch, navigate]);

  const verifyEmail = (email) => {
    if (email.length) {
      setWorking(true);
      signInWithEmailLink(auth, email, window.location.href)
        .then((result) => {
          const user = result.user;
          const { displayName, email, emailVerified, photoURL, uid } = user;
          const currUser = {
            displayName: displayName || "",
            email,
            emailVerified,
            photoURL,
            uid,
          };
          readUser(currUser).then((res) => {
            dispatch(save(res));
            dispatch(toggleAuth(true));
          });
          successNotify({
            message: `Hurray!! Welcome to ChatGDA, ${
              currUser.displayName || null
            }`,
            code: "Data Refreshed",
          });
        })
        .catch((error) => {
          console.log("Email Verification Error", error.message);
          window.location.reload();
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
        });
    } else {
      const errorMessage = getRandomErrorMessage();
      errorNotify({
        message: errorMessage,
        code: "Email Error",
      });
    }
  };
  return (
    <div style={{ margin: "auto", maxWidth: "80%", height: "inherit" }}>
      <div className={classes.body}>
        <Title className={classes.title}>Activate Your Account</Title>

        <Divider label="Account Activation" labelPosition="center" my="lg" />

        <Text fz="sm" c="dimmed">
          <TextTyping
            rawText={
              "Input the email you used to Connect to complete your ChatGDA Account Activation."
            }
          />
          {/* You will never miss important product updates, latest news and
          community QA sessions. Our newsletter is once a week, every Sunday. */}
        </Text>

        <div className={classes.controls}>
          <TextInput
            placeholder="Your email"
            classNames={{ input: classes.input, root: classes.inputWrapper }}
            value={uEmail}
            onChange={(event) => setUEmail(event.target.value)}
          />
          <Button
            className={classes.control}
            style={{ background: "indigo" }}
            onClick={() => verifyEmail(uEmail)}
            loading={working}
          >
            {working ? "Activating..." : " Activate!"}
          </Button>
        </div>
      </div>
    </div>
  );
}
