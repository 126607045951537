import {
  createStyles,
  Header,
  Burger,
  ActionIcon,
  useMantineColorScheme,
  Avatar,
  Menu,
} from "@mantine/core";
import { IconMoonStars, IconSun } from "@tabler/icons";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/Onitsha.gif";
import { FlexRow } from "../../components/Flex";
import Text from "../../components/Text";
import { logout } from "../../store/user/userReducer";
import { getAuth } from "firebase/auth";
import { useDispatch, useSelector } from "react-redux";
import { IconLogout2 } from "@tabler/icons-react";
import { toggleAuth } from "../../store/auth/authReducer";
import travel from "../../utils/travel";
import Cookies from "js-cookie";

const useStyles = createStyles((theme) => ({
  header: {
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
    width: "100%",
    borderLeft: "none",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    height: "10vh",
  },

  inner: {
    justifyContent: "space-evenly",
    alignItems: "center",
    width: "100%",
    height: "auto",
  },
  logoZone: {
    height: "inherit",
    [theme.fn.largerThan("md")]: {
      width: "20% !important",
    },
  },
  burger: {
    [theme.fn.largerThan("md")]: {
      display: "none",
    },
  },
  mobileSearch: {
    [theme.fn.largerThan("md")]: {
      display: "none",
    },
    width: "70%",
  },
  links: {
    [theme.fn.smallerThan("md")]: {
      display: "none !important",
      color: "indigo",
    },
  },

  search: {
    [theme.fn.smallerThan("xs")]: {
      display: "none",
    },
    width: "30%",
    marginLeft: "1em",
  },

  link: {
    display: "block",
    lineHeight: 1,
    padding: "8px 12px",
    borderRadius: theme.radius.sm,
    textDecoration: "none",
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,

    "&:hover": {
      backgroundColor: theme.colorScheme === "dark" ? "#f2e0ff" : "#f2e0ff",
      color: "indigo",
    },
  },
}));

export function HeaderSearch({ links, opened, toggle, activeLink }) {
  const { classes } = useStyles();
  const navigate = useNavigate();

  const auth = getAuth();
  const dispatch = useDispatch();

  const onLogout = () => {
    auth.signOut().then((res) => {
      // console.log("Logout", res);
      Cookies.remove("userID");
      dispatch(logout());
      dispatch(toggleAuth(false));
    });
  };
  const items = links.map((link, index) =>
    link.link.includes("http") ? (
      <a
        key={index}
        href={link.link}
        style={{ color: "inherit", textDecoration: "inherit" }}
        target="_blank"
        rel="noreferrer"
      >
        <div
          key={index}
          className={classes.link}
          // onClick={() => handleNavigate(link.link, link.label)}
          // onClick={() => travel(navigate, link.link, {})}
          style={{
            textDecoration: link.link === activeLink ? "underline" : null,
            cursor: "pointer",
          }}
        >
          {link.label}
        </div>
      </a>
    ) : (
      <div
        key={index}
        className={classes.link}
        // onClick={() => handleNavigate(link.link, link.label)}
        onClick={() => travel(navigate, link.link, {})}
        style={{
          textDecoration: link.link === activeLink ? "underline" : null,
          cursor: "pointer",
        }}
      >
        {link.label}
      </div>
    )
  );

  // const handleNavigate = (link, data = "") => {
  //   navigate(`/${link}`, { state: data });
  //   // navigate(`/${link}`);
  // };

  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const dark = colorScheme === "dark";
  const user = useSelector((state) => state.user);
  return (
    <Header className={classes.header}>
      <FlexRow className={classes.inner}>
        <FlexRow className={classes.logoZone}>
          <Burger
            opened={opened}
            onClick={toggle}
            size="sm"
            style={{ paddingRight: "1em", width: "10%" }}
            className={classes.burger}
          />

          <FlexRow
            style={{
              justifyContent: "center",
              cursor: "pointer",
            }}
          >
            <img
              width="40px"
              height="60%"
              src={Logo}
              alt="O"
              onClick={() => (window.location.href = "/")}
            />
            <Text
              fontSize={22}
              fontWeight={600}
              onClick={() => (window.location.href = "/")}
            >
              ChatGDA
            </Text>
          </FlexRow>
        </FlexRow>

        <FlexRow style={{ height: "15vh" }} className={classes.links}>
          {items}
        </FlexRow>

        <FlexRow width="15%" justifyContent="space-evenly">
          {user?.uid?.length ? (
            <Menu
              transitionProps={{ transition: "pop" }}
              withArrow
              position="bottom-end"
              withinPortal
            >
              <Menu.Target>
                <ActionIcon>
                  <Avatar
                    src={user.photoURL}
                    size="md"
                    style={{ cursor: "pointer" }}
                  />
                </ActionIcon>
              </Menu.Target>
              <Menu.Dropdown
                style={{
                  marginTop: "1.5em",
                }}
              >
                <Menu.Item
                  onClick={() => toggleColorScheme()}
                  color={dark ? "yellow" : "black"}
                  icon={
                    dark ? (
                      <IconSun size="1rem" stroke={1.5} />
                    ) : (
                      <IconMoonStars size="1rem" stroke={1.5} />
                    )
                  }
                >
                  {dark ? "Lights On" : "Lights Out"}
                </Menu.Item>
                <Menu.Item
                  icon={<IconLogout2 size="1rem" stroke={1.5} />}
                  color="red"
                  onClick={() => onLogout()}
                >
                  Disconnect
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          ) : (
            <ActionIcon
              variant="outline"
              color={dark ? "yellow" : "indigo"}
              onClick={() => toggleColorScheme()}
              title="Toggle color scheme"
            >
              {dark ? <IconSun size={18} /> : <IconMoonStars size={18} />}
            </ActionIcon>
          )}
        </FlexRow>
      </FlexRow>
    </Header>
  );
}
