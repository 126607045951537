const emailErrorMessages = [
  "Oops! Email is missing.",
  "Combi, we need an email!",
  "Email? Don't forget to type.",
  "No email, no entry!",
  "Lost without your email?",
  "Email field is feeling lonely.",
  "Warning: Email required for entry.",
  "Emailless? Access denied, friend.",
  "Email missing, maybe? (Hint: Yes!).",
  "Stop for dey! email required.",
  "Preep!! Show Email.",
  "Oops! Email no dey.",
  "Eish! We need email.",
  "Email? Nor forget for type am.",
  "If email no dey, no access!",
  "Where email? We di wait.",
  "Email field dey lonely small.",
  "Warning: Email na must.",
  "No email, no entry, bros.",
];
const emptyMessageErrorMessages = [
  "No message, just crickets!",
  "Oops! Where's the message?",
  "Message missing in action!",
  "Empty words, empty message.",
  "Words? Write something, friend!",
  "Send me your thoughts, please!",
  "Message void of textiness.",
  "Uh-oh! Textless message detected.",
  "Empty message, empty conversation.",
  "Message needed, thoughts wanted!",
  "No message? where ya tori?",
  "Hmmm! Text no enter oh!.",
  "Tori no dey inside!",
  "Where message?",
  "Write anything, abeg!",
  "Eish! Where ya message?",
  "No message, no yan!",
  "Na empty message you send.",
  "Message dey empty, tori miss!",
  "Bros ya text No show!",
];
const nameErrorMessages = [
  "Oops! Name is missing.",
  "Combi, we need a Name!",
  "Name? Don't forget to type.",
  "No Name, no entry!",
  "Lost without your Name?",
  "Name field is feeling lonely.",
  "Warning: Name required for entry.",
  "Nameless? Access denied, friend.",
  "Name missing, maybe? (Hint: Yes!).",
  "Stop for dey! Name required.",
  "Preep!! Show Name.",
  "Oops! Name no dey.",
  "Eish! We need Name.",
  "Name? Nor forget for type am.",
  "If Name no dey, no access!",
  "Where Name? We di wait.",
  "Name field dey lonely small.",
  "Warning: Name na must.",
  "No Name, no entry, bros.",
];

const getRandomErrorMessage = () => {
  const randomIndex = Math.floor(Math.random() * emailErrorMessages.length);
  return emailErrorMessages[randomIndex];
};
export const getRandomSendErrorMessage = () => {
  const randomIndex = Math.floor(
    Math.random() * emptyMessageErrorMessages.length
  );
  return emptyMessageErrorMessages[randomIndex];
};
export const getRandomNameErrorMessage = () => {
  const randomIndex = Math.floor(Math.random() * nameErrorMessages.length);
  return nameErrorMessages[randomIndex];
};
export default getRandomErrorMessage;
// Example usage:
// const errorMessage = getRandomErrorMessage();
// console.log(errorMessage);
