import { useDisclosure } from "@mantine/hooks";
import React, { useEffect, useState } from "react";
import { FlexColumn, FlexRow } from "../components/Flex";
import { Drawer } from "../header/components/Drawer";
import Header from "../header/Header";
import {
  Button,
  Modal,
  TextInput,
  Transition,
  useMantineTheme,
} from "@mantine/core";
import Scrollbars from "react-custom-scrollbars-2";
import { useSelector } from "react-redux";
import { errorNotify } from "../header/components/Notify";
import { getRandomNameErrorMessage } from "../workers/errorMessage";
import { editUser } from "../store/user/userJobs";

const View = ({ link, children }) => {
  const [opened, { toggle }] = useDisclosure(false);
  const [named, { open }] = useDisclosure(false);
  const auth = useSelector((state) => state.auth);
  const user = useSelector((state) => state.user);
  const [working, setWorking] = useState(false);

  const [name, setName] = useState("");

  useEffect(() => {
    if (auth && user.email.length) {
      if (!user?.displayName?.length) {
        open();
      }
    }
  }, [auth, open, user?.displayName?.length, user?.email?.length]);
  // const isMobile = useMediaQuery("(max-width: 50em)");
  const theme = useMantineTheme();

  const saveName = async () => {
    setWorking(true);
    if (name.length) {
      const newU = {
        ...user,
        displayName: name,
      };
      await editUser(newU).then(setWorking(false));
    } else {
      errorNotify({
        code: "Error",
        message: getRandomNameErrorMessage(),
      });
    }
  };

  return (
    <FlexRow className="view mainBodyLight oScrollBar">
      <Modal
        opened={named}
        // onClose={close}
        title="ChatGDA:"
        centered
        overlayProps={{
          color:
            theme.colorScheme === "dark"
              ? theme.colors.dark[9]
              : theme.colors.gray[2],
          opacity: 0.55,
          blur: 3,
        }}
        // fullScreen={isMobile}
        closeOnClickOutside={false}
      >
        <TextInput
          data-autofocus
          autoFocus
          label="What can I call you?"
          placeholder="Ex: Francis"
          mt="md"
          mb="sm"
          value={name}
          onChange={(e) => setName(e.target.value)}
          disabled={working}
        />
        <FlexColumn width="100%">
          <Button
            variant="default"
            style={{ background: "indigo", width: "50%" }}
            onClick={() => saveName()}
            loading={working}
            disabled={working}
          >
            Send!
          </Button>
        </FlexColumn>
      </Modal>
      <Transition
        mounted={opened}
        transition="scale-y"
        duration={500}
        timingFunction="ease"
      >
        {(styles) => (
          <FlexColumn style={{ ...styles, width: "auto" }}>
            <Drawer />
          </FlexColumn>
        )}
      </Transition>
      <Scrollbars style={{ height: "100vh", width: "100vw" }}>
        <FlexColumn
          style={{
            height: "100vh",
            width: "auto",
            minWidth: "90vw",
          }}
        >
          <Header activeLink={link} opened={opened} toggle={toggle} />
          <Transition
            mounted
            transition="fade"
            duration={1500}
            timingFunction="ease"
          >
            {(styles) => (
              <FlexColumn
                style={{
                  ...styles,
                  // minHeight: "90vh",
                  margin: "auto",
                  height: "90vh",
                }}
              >
                {children}
              </FlexColumn>
            )}
          </Transition>
        </FlexColumn>
      </Scrollbars>
    </FlexRow>
  );
};

export default View;
