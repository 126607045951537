import {
  createStyles,
  Text,
  Title,
  TextInput,
  Button,
  Image,
  Divider,
} from "@mantine/core";
import image from "./assets/comingSoon.svg";
import TextTyping from "../../components/TypingText";

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
    padding: `calc(${theme.spacing.xl} * 2)`,
    borderRadius: theme.radius.md,
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.white,
    border: `${"1px"} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.colors.gray[3]
    }`,

    [theme.fn.smallerThan("sm")]: {
      flexDirection: "column-reverse",
      padding: theme.spacing.xl,
    },
    height: "inherit",
    justifyContent: "center",
  },

  image: {
    maxWidth: "40%",

    [theme.fn.smallerThan("sm")]: {
      maxWidth: "100%",
    },
  },

  body: {
    // paddingRight: `calc(${theme.spacing.xl} * 4)`,

    [theme.fn.smallerThan("sm")]: {
      //   paddingRight: 0,
      marginTop: theme.spacing.xl,
    },
    padding: "0em 1em 0em 1em",
  },

  title: {
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    lineHeight: 1,
    marginBottom: theme.spacing.md,
  },

  controls: {
    display: "flex",
    marginTop: theme.spacing.xl,
  },

  inputWrapper: {
    width: "100%",
    flex: "1",
  },

  input: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRight: 0,
  },

  control: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
}));

export function ComingSoon() {
  const { classes } = useStyles();
  return (
    <div
      className={classes.wrapper}
      style={{ width: "inherit", height: "inherit" }}
    >
      <div className={classes.body}>
        <Title className={classes.title}>Come back soon...</Title>

        <Divider label="Coming Soon" labelPosition="center" my="lg" />

        <Text fz="sm" c="dimmed">
          <TextTyping
            rawText={
              "At GDA We strive for Perfection so we are always working to improve our solutions. Come back soon or leave us your email so we make sure you are amongst the first to know when this or more features are available for you."
            }
          />
          {/* You will never miss important product updates, latest news and
          community QA sessions. Our newsletter is once a week, every Sunday. */}
        </Text>

        <div className={classes.controls}>
          <TextInput
            placeholder="Your email"
            classNames={{ input: classes.input, root: classes.inputWrapper }}
          />
          <Button className={classes.control} style={{ background: "indigo" }}>
            Subscribe!
          </Button>
        </div>
      </div>
      <Image src={image} className={classes.image} />
    </div>
  );
}
