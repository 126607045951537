import React from "react";
import { FlexColumn, FlexRow } from "../../components/Flex.js";
import View from "../View.js";
import "./Start.css";
import { Login } from "../Auth/Login.js";

const Start = () => {
  return (
    <View link={"/start"}>
      <FlexColumn
        style={{
          height: "100%",
          margin: "auto",
          alignItems: "center",
          overFlowX: "hidden",
        }}
        justifyContent="flex-start"
      >
        <FlexRow
          style={{
            width: "320px",
            overFlowX: "hidden",
            scrollSnapType: "x mandatory",
            scrollBehavior: "smooth",
            scrollBarWidth: "none",
            msOverflowStyle: "none",
            "&::WebkitScrollbar": {
              display: "none",
            },
            marginTop: "1em",
          }}
        >
          <Login />
        </FlexRow>
      </FlexColumn>
    </View>
  );
};

export default Start;
