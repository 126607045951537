import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import routes from "./routes";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { MantineProvider, ColorSchemeProvider } from "@mantine/core";
import { useLocalStorage } from "@mantine/hooks";
import authRoutes from "./authRoutes";
import { useSelector } from "react-redux";
import Empty from "./header/components/Empty";
import "highlight.js/styles/default.css";

const App = () => {
  const toggleColorScheme = (value) =>
    setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"));

  const [colorScheme, setColorScheme] = useLocalStorage({
    key: "mantine-color-scheme",
    defaultValue: "dark",
    getInitialValueInEffect: true,
  });

  // useEffect(() => {
  //   axios
  //     .get("http://localhost:3001/api/data")
  //     .then((response) => {
  //       // Handle the response data
  //       // console.log(response.data);
  //       successNotify({
  //         code: `Success - ${response.status}`,
  //         message: response.data.message,
  //       });
  //     })
  //     .catch((error) => {
  //       // Handle any errors
  //       console.error(error);
  //     });
  // }, []);

  const auth = useSelector((state) => state.auth);

  return (
    <ColorSchemeProvider
      colorScheme={colorScheme}
      toggleColorScheme={toggleColorScheme}
    >
      <MantineProvider
        theme={{ colorScheme }}
        withGlobalStyles
        withNormalizeCSS
      >
        <BrowserRouter>
          <div
            style={{ padding: "0px", maxWidth: "100vw", height: "100vh" }}
            className="App oScrollBar"
          >
            <Routes>
              {auth ? (
                routes.map((route, index) => (
                  <Route
                    path={route.path}
                    element={route.component}
                    key={index}
                  />
                ))
              ) : auth === false ? (
                authRoutes.map((route, index) => (
                  <Route
                    path={route.path}
                    element={route.component}
                    key={index}
                  />
                ))
              ) : (
                <Route path="*" element={<Empty />} key={0} />
              )}
            </Routes>
          </div>
        </BrowserRouter>
      </MantineProvider>
    </ColorSchemeProvider>
  );
};

export default App;
