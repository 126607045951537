import { createSlice } from "@reduxjs/toolkit";
import {
  Timestamp,
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { db } from "../../services/firebaseConfig";
import { id } from "../../utils/idGenerator";

export const chatsSlice = createSlice({
  name: "chats",
  initialState: [],
  reducers: {
    chatSave: (state, action) => {
      // console.log("Save to Store", action.payload);
      state = [...action.payload];
      return state;
    },
    get: async (state, action) => {
      const q = query(
        collection(db, "CHATS"),
        where("userID", "==", action.payload)
      );
      const querySnapshot = await getDocs(q);
      const data = [];
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        // console.log(doc.id, " => ", doc.data());
        data.push(doc.data());
      });
      // console.log("Chats Data", data);
      if (data.length) chatSave(data);
    },
    create: async (state, action) => {
      const chatID = id;
      const now = new Date();
      // Add a new document in collection "cities"
      const final = {
        messages: [...action.payload.messages],
        creationDate: Timestamp.fromDate(now),
        updateDate: Timestamp.fromDate(now),
        isDeleted: false,
        lastUseDate: Timestamp.fromDate(now),
        name: action.payload.name,
        userID: action.payload.userID,
        chatID: chatID,
      };
      // console.log("Final Data:", final);
      await setDoc(doc(db, "CHATS", chatID), {
        ...final,
      });
      return final;
    },
    update: async (state, action) => {
      const now = new Date();
      // Add a new document in collection "cities"
      const final = {
        messages: [...action.payload.messages],
        updateDate: Timestamp.fromDate(now),
        isDeleted: action.payload.type === "chats/delete" ? true : false,
        lastUseDate: Timestamp.fromDate(now),
        name: action.payload.name,
      };
      // console.log("Final Data:", final);
      await setDoc(
        doc(db, "CHATS", action.payload.chatID),
        {
          ...final,
        },
        { merge: true }
      );
      return final;
    },
  },
});

export const { get, create, chatSave } = chatsSlice.actions;
export default chatsSlice.reducer;
