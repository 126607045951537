export const formatDate = (value) => {
  let date = new Date(value?.seconds * 1000).toDateString("en-US");
  return date;
};
export const formatDateTime = (value) => {
  let date = new Date(value?.seconds * 1000).toString();
  return date;
};

export const formatDateSecs = (value) => {
  let date = new Date(value * 1000);
  return date;
};
