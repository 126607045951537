import { Timestamp, doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../../services/firebaseConfig";
import { loadingNotify } from "../../header/components/Notify";

export const readUser = async (data) => {
  const docRef = doc(db, "USERS", data?.uid);
  let loaded = false;
  let docSnap;
  await getDoc(docRef).then((res) => (docSnap = res));
  const user = docSnap.data();
  // console.log("DB User", user);
  if (user?.uid.length) {
    // console.log("Firestore User:", user);
    return user;
  } else {
    loadingNotify({
      preCode: "Working...",
      preMessage: "Creating your Profile...",
      state: loaded,
      postCode: "Success!",
      postMessage: "Profile Created Successfully.",
    });
    // console.log("New User", data);
    const now = new Date();
    const final = {
      ...data,
      creationDate: Timestamp.fromDate(now),
      updateDate: Timestamp.fromDate(now),
      tokens: 15000,
      newHigh: 15000,
    };
    // console.log("Final Data:", final);
    await setDoc(doc(db, "USERS", data?.uid), {
      ...final,
    });
    loaded = true;
    return final;
  }
};

export const editUser = async (data) => {
  // console.log("Editing User");
  const now = new Date();
  const final = {
    ...data,
    updateDate: Timestamp.fromDate(now),
  };
  // console.log("Final Data:", final);
  await setDoc(doc(db, "USERS", data?.uid), {
    ...final,
  });

  window.location.reload();
  return final;
};
