import { createSlice } from "@reduxjs/toolkit";

export const promptSlice = createSlice({
  name: "prompt",
  initialState: "text",
  reducers: {
    setPrompt: (state, action) => {
      state = action.payload;
      return state;
    },
  },
});

export const { setPrompt } = promptSlice.actions;
export default promptSlice.reducer;
